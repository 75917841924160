<template>
  <v-card>
    <v-card-title>
      Create Project
    </v-card-title>

    <ProjectFrom 
      :project="project"
      type="CREATE"
    />
  </v-card>
</template>

<script>
import ProjectFrom from "./form"

export default {
  components: {
    ProjectFrom
  },
  data() {
    return {
      project: {
        activities:[],
      }
    };
  },
}
</script>